
:root{
  --primary: #FFE01B;
  --primary2: #FFDF4C;
  --secondary: #017A6F;
  --secondary2: #0096A6;
  --third2: #80387D;
  --third: #884485;
  --gray3: #D6D6D6;
  --gray2: #F1EEEE;
  --gray1: #F9F9F9;
  --gray4: #8E8E8E;
  --gray5: #E0E0E0;


  --text-base: #000000;
  --text-primary: #8E8E8E;
  --text-secondary: #0096A6;
  --text-gray: #DADADA;
  --text-gray2: #E0E0E0;
  

  --font-roboto: 'Roboto', -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue',
    'Helvetica', sans-serif;

}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../public/fonts/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/roboto-v30-latin-300.woff') format('woff'); /* Modern Browsers */
  font-display: swap;
}


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../public/fonts/roboto-v30-latin-regular.woff') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/roboto-v30-latin-regular.woff') format('woff'); /* Modern Browsers */
  font-display: swap;


}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('../public/fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/roboto-v30-latin-500.woff') format('woff'); /* Modern Browsers */
  font-display: swap;
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../public/fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/fonts/roboto-v30-latin-700.woff') format('woff'); /* Modern Browsers */
  font-display: swap;  
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E5E5E5;
}



body {
  font-family: var(--font-roboto);
  position: relative;
  min-height: 100%;
  margin: 0;
}

main {
  max-width: 970px;
  background-color:white;

}

.fondo404 {
  background: linear-gradient(44deg, #52114A 31.63%, #823779 70.83%);
}

.calculator {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;

}

.acordion {
  width: 50vh;
}

.item {
  margin-bottom: 5px;
  padding: 10px 20px;
}

.title {
  color: #81397e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content>li {
  background-color: #009688;
  color: #FFFFFF;
  list-style: none;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  border-radius: 5px;
  margin: 2px;
  padding-left: 16px;
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.banner.show {
  max-height: 90px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.content>li:hover {
  background-color: #03665c;
}

.borderEquivalencia {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  height: auto;

}

.titleDesign {
  position: absolute;
  left: 57%;
  right: 0.29%;
  top: 40%;
  bottom: 0.5%;
  width: 342px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;

  color: #FFFFFF;

}

.titleBrand {
  position: absolute;
  left: 24%;
  right: -229.34%;
  top: 6%;
  bottom: 18%;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 40px;
  /* identical to box height, or 96% */

  letter-spacing: -0.02em;

  color: #FFFFFF;
}

.gradient {
  background: linear-gradient(67.6deg, #FFDF4C 6.79%, #0096A6 67.73%);
}

.gradient1 {
  background: linear-gradient(67.6deg, #834483 20.79%, #027c8a 67.73%);
}

.gradient2 {
  background: linear-gradient(67.6deg, #027c8a 20.79%, #834483 67.73%);
}

.nav-data {
  display: flex;
  max-width: 50.1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@keyframes rightToLeft {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-70%);
  }

  100% {
    transform: translateX(0%);
  }

}

@keyframes rightToLeft2 {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 780px) {
  .nav-data {
    max-width: 100%;
  }
}
/* fix en 768px */
@media screen and (width: 768px) {
  .exchange_img {
    min-width: unset !important;
    max-width: unset !important;
    width: 100px !important;
  }
  .exchange_div {
    min-width: unset !important;
    max-width: unset !important;
    width: 145px !important;
  }
}

@media (min-width: 970px) {
  .target {
    transition: 50s linear;
    -webkit-animation: rightToLeft2 50s linear !important;
    animation: rightToLeft2 50s linear !important;
    -webkit-animation-iteration-count: infinite !important;
    animation-iteration-count: infinite !important;
  }

  .gridArticle{
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
  }
}


.target {
  transition: 50s linear;
  -webkit-animation: rightToLeft 50s linear;
  animation: rightToLeft 50s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.target-2 {
  transition: 75s linear;
  -webkit-animation: rightToLeft2 75s linear;
  animation: rightToLeft2 75s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.transformText {
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.04em;
  color: #81397e;
  text-shadow: 0.5px 0 0 #ffdf4c, -0.5px 0 0 #ffdf4c, 0 0.5px 0 #ffdf4c,
    0 -0.5px 0 #ffdf4c, 0.5px 0.5px #ffdf4c, -0.5px -0.5px 0 #ffdf4c,
    0.5px -0.5px 0 #ffdf4c, -0.5px 0.5px 0 #ffdf4c;
  text-align: center;
}

.shadowPromote {
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: -10px;
  padding-bottom: 15px;
}

.containerConverter {
  top: -40px
}

.leaflet-container {
  
  height: 40vh;
}

.backgroundMoney {
  background-color: #0096A6;
  color: #FFFFFF;
  font-size: xx-large;
  padding: 0;
  margin: 0;
}

.containerMoney {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.containerEquivalence {
  grid-column: span 2;
  justify-content: center;
}

.containerDetected {
  grid-column: span 1;
}

.containerInitial {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 20px;
}

.containerHome {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 1000px) {
  .containerConverter {
    margin-bottom: 10px;
    width: 100%;
  }

  .containerMoney {
    grid-template-columns: repeat(3, 1fr);
  }

  .containerEquivalence {
    grid-column: span 2;
    margin-bottom: 15px;
  }

  .containerDetected {
    grid-column: span 1;
  }

}


@media (max-width: 880px) {
  .containerMoney {
    grid-template-columns: repeat(1, auto);
  }

  .containerInitial {
    grid-template-columns: repeat(1, 1fr);
  }

  .containerConverter {
    margin-top: 30px;
    max-width: 400px;
    justify-content: center;
  }

  .borderEquivalencia {
    margin-bottom: 20px;
  }

  .containerEquivalence {
    grid-column: span 1;
  }

  .containerDetected {
    grid-column: span 1;
  }

  .containerHome {
    grid-template-columns: repeat(1, 1fr);
  }

}

.MuiAccordionSummary-root {
  margin: 0px;
}

.bg-blackOpacity {
  background-color: rgba(0, 0, 0, 0.5);
}

.selectGeopraphi {
  background: #FAFAFA;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12),
    0px 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
}

.containerCalendar {
  position: relative;
}

.containerCalendar .slider {
  display: flex;
  background: #011b27;
  padding: 2px;
  width: 600px;
  height: 150px;
  overflow: hidden;
  scroll-behavior: smooth;
}

.containerCalendar .slider img {
  margin: 3px;
}

.containerCalendar .control {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #02293b;
  cursor: pointer;
  object-fit: contain;
  padding: 10px;
}

.containerCalendar .prev {
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.containerCalendar .next {
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
}

.compra {
  position: absolute;
  left: 52.83%;
  right: 45%;
  top: 22.57%;
  bottom: 77.27%;
  background: #007C89;
}

.containerCalcular{
  width: 100%;
  background: linear-gradient(14.79deg, rgba(136,68,133,1) 26.75%, rgba(6,123,135,1) 77.65%);
}
.containerWUnion{
  width: 100%;
  background: linear-gradient(90deg, rgba(254,222,79,1) 0%, rgba(97,90,47,1) 7%, rgba(29,33,33,1) 20%, rgba(24,29,32,1) 100%);
}

.article{
  @apply flex w-full justify-between border-gray-400;
  padding: 15px;
  border-top: 1px solid #DADADA;
}

.h2_article{
  @apply text-2xl font-bold;
  color: #0096A6;
  margin-bottom: 10px;
}

.video-responsive {
  position: relative;
  padding-bottom: 56.25%;; /* 16/9 ratio */
  padding-top: 30px; /* IE6 workaround*/
  height: 0;
  overflow: hidden;
  }
  
  .video-responsive img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  }

  .video-responsive-home {
    position: relative;
    padding-bottom: 40.25%;; /* 16/9 ratio */
    padding-top: 30px; /* IE6 workaround*/
    height: 0;
    overflow: hidden;
    }
    
    .video-responsive-home img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    }

  .icon {
    display: inline-block;
    width: 23px;
    height: 23px;
    background-size: cover;
  }
  
  .icon-arrow {
    background-image: url(../public/icons/downArrow.svg);
  }
  
  .icon-arrow:hover {
    background-image: url(../public/icons/downArrowHover.svg);
  }

  .component_625{
    background: linear-gradient(205.1deg, #80387D 33.46%, #CE232D 83.79%);
    box-shadow: 0px -1px 4px rgba(0, 0, 0,0.25);
  
  }
  .icon_calcular{
    transform: translate3d(0px, 0px, 0px);
    transform: unset !important;
  }

.billboard-height, .leaderboard-height {
  min-height: 336px !important;
}
@media (min-width: 1001px) {
  .billboard-height {
      min-height: 250px !important;
  }
  .leaderboard-height {
      min-height: 90px !important;
  }
}

/* static tipo-cambio-sunat */

text p {  
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 1.5;
  background-color: black;
}
  
  
