.rdrWeekDay{
  /* flex-basis: calc(100% / 8) !important; */
}
.rdrDay{
  /* width: calc(100% / 8) !important; */
}
.rdrDays{
  flex-wrap: unset !important;
  display: grid !important;
  flex-wrap: unset;
  grid-template-columns: repeat(7,1fr);
  gap: 0px 6px;
}
.rdrMonth{
  width: 20em !important;
  padding-bottom: 8px !important;
}
.rdrMonthAndYearPickers select{
  background: none !important;
  padding: 10px 5px !important;
}
.rdrYearPicker select{
  padding-right: 20px !important;
  background: url('data:image/svg+xml;utf8,<svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.54" fill-rule="evenodd" clip-rule="evenodd" d="M0 0L5 5L10 0H0Z" fill="black"/></svg>') no-repeat !important;
  background-position: right 0px center !important;
}

.rdrNextPrevButton{
  width: 26px !important;
  height: 26px !important;
  border-radius: 13px !important;
}
.rdrMonthAndYearWrapper{
  flex-direction: row;
}
.rdrPprevButton i{
  display: none !important;  
}
.rdrPprevButton{
  order: 2;
  margin: 0 0.41em !important;
  background: url('data:image/svg+xml;utf8,<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.71211 0.333656C6.32825 -0.11122 5.70589 -0.11122 5.32203 0.333656L1.87283e-06 6.5L5.32203 12.6663C5.70589 13.1112 6.32825 13.1112 6.71211 12.6663C7.09597 12.2215 7.09597 11.5002 6.71211 11.0553L2.78016 6.5L6.71211 1.9447C7.09597 1.49982 7.09597 0.778533 6.71211 0.333656Z" fill="black"/></svg>') no-repeat !important;
  background-position: right 9px center !important;
  border: 1px solid #000 !important;
}
.rdrMonthAndYearPickers{
  order: 1;
  margin: 0 0.82em !important;
  background: #FAFAFA;
  border-radius: 5px;
  box-shadow: 0px 1px 3px -2px #000000 !important
}

.rdrNextButton i{
  display: none !important;
}
.rdrNextButton{
  order: 3;
  margin: 0 0.41em !important;
  background: url('data:image/svg+xml;utf8,<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.287894 12.6663C0.671754 13.1112 1.29411 13.1112 1.67797 12.6663L7 6.5L1.67797 0.333657C1.29411 -0.111219 0.671755 -0.111219 0.287895 0.333657C-0.095965 0.778533 -0.0959651 1.49982 0.287895 1.9447L4.21984 6.5L0.287894 11.0553C-0.0959656 11.5002 -0.0959656 12.2215 0.287894 12.6663Z" fill="black"/></svg>') no-repeat !important;
  background-position: right 7px center !important;
  border: 1px solid #000 !important;
}

.rdrInRange{
  color: #EAFDFF !important;
  width: 37px;
}

.rdrInRange + .rdrDayNumber span{
  color: #0096A6 !important;
}
.rdrWeekDays{
  border-bottom: 1px solid #ccc;
  gap: 10px !important;
}
.rdrDayNumber{
  width: 26px;
  border: 1px solid #0096A6;
  border-radius: 15px;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge{
  right: 0px !important;
  left: 0px !important;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge{
  right: 0px !important;
  left: 1px !important;
}
.rdrDayPassive{
  color: #ccc !important;
}
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after{
  display: none !important;
}
.rdrDayToday .rdrDayNumber span:after{
  display: none !important;
}
.rdrDayNumber span{
  color: unset !important;
}
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span{
  color: rgba(255, 255, 255, 0.85) !important;
}
.rdrStartEdge {
  left: 1px !important;
  border-radius: 1.042em !important;
  width: 25px !important;
}
.rdrEndEdge{
  left: 1px !important;
  right: 1px !important;
  border-radius: 1.042em !important;
  width: 25px !important;
}
.rdrWeekDay{
  font-size: 0px;
}
.rdrWeekDay:first-letter {
  font-size: 12px;
  font-weight: 400;
  line-height: 2.6em;
  text-transform: uppercase;
  color: #000000;
}
.btnFilter{
  margin-top: 5px;
  padding: 6px 25px;
  color: #fff;
  border-radius: 15px;
  background: #009688;
}

